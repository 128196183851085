.heroSectionContainer {
  height: 70vh;
  width: 100%;
  position: relative;
  color: #0c171b;
  z-index: 0;
}

.glide {
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 500;
}

.glide__track,
.glide__slides,
.glide__slide,
.glide__slide img {
  height: 100%;
}

.glide__slides img {
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.glide__arrow {
  padding: 1rem 12px !important;
  background-color: #2b78a7 !important;
  border: none !important;
  outline: none !important;
}

.glide__arrow:hover {
  background-color: #ff5e00 !important;
  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.searchResults {
  display: flex;
  flex-direction: column;
}
.headerSearch {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, 50%);
  width: 40%;
  border-radius: 10px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* From https://css.glass */
  background: rgb(0 0 0 / 25%);
  border-radius: 22px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: all 1s ease-in-out;
}
.headerSearchOuterContainer {
  display: flex;
  flex: 1;
}
.headerSearchOuterContainer input {
  background-color: transparent;
}

.headerSearchOuterContainer input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.headerSearchOuterContainer input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.headerSearchOuterContainer input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.headerSearchOuterContainer > div {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.headerSearchOuterContainer > div:nth-of-type(1) {
  width: 50%;
}
.headerSearchOuterContainer > div:nth-of-type(2) {
  /* width: 10%; */
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.headerSearch button {
  padding: 0.4rem 1rem;
  background: #ff5e00;
  border: none;
  color: white;
  outline: none;
  border-radius: 8px;
  font-weight: 600;
}
.headerSearch > div {
  padding: 0 0 0 1rem;
  width: 100%;

  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  position: relative;
}
.searchData {
  margin-top: -2px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  min-height: 0;
  max-height: 30vh;
  overflow-y: scroll;
  border-radius: 10px;
}
.searchData > a > div {
  background: white;
  display: flex;
  margin: 0.5rem;
}

.searchData > a > div > div:nth-of-type(2),
.searchData > a > div > div:nth-of-type(3) {
  flex: 1;
  margin: 0 0.5rem;
}
.searchData > a > div > div:nth-of-type(3) {
  display: flex;
  justify-content: flex-end;
}
.searchData > a > div img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}
.headerSearch > div > input {
  background-color: transparent;
  padding: 0 !important;
  height: 100%;
}

@media (max-width: 1100px) {
  .glide {
    height: 60vh;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 500;
  }
  .heroSectionContainer {
    height: 60vh;
    width: 100%;
    position: relative;
    color: #0c171b;
  }
}

@media (max-width: 768px) {
  .headerSearch button {
    display: none;
  }

  .glide {
    height: 30vh;
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 500;
  }
  .heroSectionContainer {
    height: 30vh;
    width: 100%;
    position: relative;
    color: #0c171b;
  }
  .headerSearch {
    width: 80vw;
  }
  .glide__arrow {
    display: none !important;
  }
  .glide__slides img {
    width: 100%;
    object-fit: cover;
    object-position: left;
  }
}
@media (max-width: 512px) {
  .headerSearch {
    display: none;
    bottom: 12%;
  }
}
