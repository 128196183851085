.order {
  padding: 1rem;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 1rem;
  width: 80%;
  color: grey;
  border-radius: 5px;
}

.order table {
  width: 100%;
  border-collapse: collapse;
}

.order th,
.order td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.order th {
  font-weight: bold;
  color: black;
}

.order td {
  color: black;
}

.order ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  .order {
    width: 90%;
  }
}
