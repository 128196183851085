@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/* .react-date-picker {
  font-family: Arial, Helvetica, sans-serif;
}

.react-date-picker__wrapper,
.react-date-picker__wrapper button {
  background-color: #2c2c2c !important;
  border: 1px solid #444 !important;
}

.react-date-picker__inputGroup {
  background-color: #2c2c2c !important;
  color: #fff !important;
}

.react-date-picker__inputGroup__input {
  color: #fff !important;
}

.react-date-picker__inputGroup__divider {
  color: #444 !important;
}

.react-date-picker__inputGroup__leadingZero {
  color: #444 !important;
}

.react-date-picker__button__icon {
  stroke: #fff !important;
}

.react-calendar {
  background-color: #2c2c2c !important;
  color: #fff !important;
  border: none !important;
}

.react-calendar__tile {
  background-color: #2c2c2c !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #444 !important;
}

.react-calendar__tile--hasActive,
.react-calendar__tile--active {
  background-color: #ff5e00 !important;
  color: #fff !important;
}

.react-calendar__tile--hasActive:hover,
.react-calendar__tile--active:hover,
.react-calendar__tile--hasActive:focus,
.react-calendar__tile--active:focus {
  background-color: #ff5e00 !important;
  color: #fff !important;
}

.react-calendar__navigation {
  background-color: #2c2c2c !important;
  border: none !important;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  color: #fff !important;
} */

.individualPeacefulStays {
  min-height: 100vh;
  width: 100%;
  background: #f0f0f0;
}

.imageModal .modal,
.trekBooking .modal {
  display: flex !important;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100% !important;
  margin: 0 !important;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
}

.AmenityContainer {
  display: flex;
  flex-wrap: wrap;
}
.AmenityContainer > div {
  border-radius: 4px;
  background-color: #f1f1f1;
  margin-bottom: 10px;
  align-items: center;
  padding: 4px;
  width: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 13px;
}

.individualPeacefulStays .modal-content {
  width: 80%;
  max-height: 90%;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.individualPeacefulStays .prev,
.individualPeacefulStays .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 48px;
  font-weight: 400;
  color: white;
  cursor: pointer;
}

.individualPeacefulStays .prev {
  left: 20px;
}

.individualPeacefulStays .next {
  right: 20px;
}

.individualPeacefulStays > div {
  width: 90%;
  margin: 0 auto 10px auto !important;
  padding-top: 10px;
}

.individualPeacefulStays .ipsDetailsContainer > div {
  margin: 0 auto 10px auto !important;
  background: white;
  padding: 1rem;
  border-radius: 10px;
}

.individualPeacefulStays > div:nth-of-type(3) {
  background: white;
  padding: 1rem;
  border-radius: 10px;
}

.ipsNav ul {
  display: flex;
  border-bottom: 1px solid #c3dcfe;
}
.ipsNav ul li {
  margin: 0 2px 0 0;
  flex: 1;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebf3fe;
}

.ipsDetailsHeading > div {
  display: flex;
  align-items: center;
}
.ipsDetailsHeading > div > div:nth-of-type(1) {
  font-size: 1.8rem;
  font-weight: 600;
}
.ipsDetailsHeading > div > div:nth-of-type(2) {
  display: flex;
}

.ipslocation {
  width: 100%;
  margin-bottom: 10px;
}
.ipsCompleteAddress {
  color: gray;
  font-size: 14px;
}
.ipslocation > svg {
  margin-right: 10px;
}

.ipsGallary {
  min-height: 50vh;
  width: 100%;
  margin: 10px 0;
}
.view-more-container {
  position: relative;
}

.ipsGallary > div {
  display: grid;
  height: 50vh;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}

.ipsGallary > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.ipsGallary > div > img:nth-of-type(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 5;
}
.ipsGallary > div > img:nth-of-type(2) {
  grid-column: 3 / 4;
  grid-row: 1 / span 2;
}
.ipsGallary > div > img:nth-of-type(3) {
  grid-column: 4 / 5;
  grid-row: 1 / span 2;
}
.ipsGallary > div > img:nth-of-type(4) {
  grid-column: 3 / 4;
  grid-row: 3 / span 2;
}
.ipsGallary > div > div {
  grid-column: 4 / 5;
  grid-row: 3 / span 2;
  border-radius: 4px;
}
.ipsGallary > div > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.view-more-container button {
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  color: white;
  background-color: transparent;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.ipsDescription {
  margin: 2rem 0;
  display: flex;
}

.ipsDescription > div:nth-of-type(1) {
  width: 100%;
}
.ipsDescription > div:nth-of-type(1) > div:nth-of-type(2) {
  padding-top: 10px;
  width: 100%;
  /* line-height: 14px; */
  /* max-height: 24px; */
  /* overflow: hidden; */
  /* transition: max-height 0.8s ease-in-out; */
}

.ipsDescription > div:nth-of-type(2) {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .ipsDescription > div:nth-of-type(2) > div {
  width: 80%;
  min-height: 80%;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 1rem;
} */

.ipsPropertyHighlight > div {
  display: flex;
  flex-direction: column;
}
.ipsPropertyHighlight > div > div:nth-of-type(1) {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 22px;
  text-align: center;
}
.ipsPropertyHighlight > div > div:nth-of-type(2) {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.ipsPropertyHighlight > div > div:nth-of-type(3) {
  margin: 10px 0;
}
.ipsPropertyHighlight > div > div:nth-of-type(3) input {
  width: 75%;
  border-radius: 4px;
  background-color: white;
  margin-left: auto;
  padding: auto 10px;
}
.ipsPropertyHighlight > div > div:nth-of-type(4) {
  margin: 10px 0;
}
.ipsPropertyHighlight > div > div:nth-of-type(4) input {
  width: 75%;
  border-radius: 4px;
  background-color: white;
  margin-left: auto;
  padding: auto 10px;
}

.ipsPropertyHighlight > div > div:nth-of-type(5) span,
.ipsPropertyHighlight > div > div:nth-of-type(6) span {
  display: flex;
}
.ipsPropertyHighlight > div > div:nth-of-type(5) span > div,
.ipsPropertyHighlight > div > div:nth-of-type(6) span > div {
  display: flex;
  width: 50%;
  margin-left: 4px;
}
.ipsPropertyHighlight > div > div:nth-of-type(5) span > div button,
.ipsPropertyHighlight > div > div:nth-of-type(6) span > div button,
.ipsHightlightButton {
  background-color: #ff5e00;
  color: #ffffff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
.ipsPropertyHighlight > div > div:nth-of-type(5) span > div button:hover,
.ipsPropertyHighlight > div > div:nth-of-type(6) span > div button:hover,
.ipsHightlightButton {
  background-color: #e65a00;
  padding: 0.5rem;
}
.ipsPropertyHighlight > div > div:nth-of-type(5) span > div > div,
.ipsPropertyHighlight > div > div:nth-of-type(6) span > div > div {
  flex: 1;
  text-align: center;
}

.ipsPropertyHighlight {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 5px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ipsPropertyHighlight .react-date-picker {
  margin-left: 10px;
}

.roomsCountControl {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.roomsCountControl button {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.25rem;
}

.ipsMostPopular {
  margin: 2rem 0;
}
.ipsMostPopular h5 {
  font-size: 1rem;
  margin: 5px 0;
}

.ipsMostPopular > div {
  display: flex;
}
.ipsMostPopular > div li {
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.ipsMostPopular > div li svg {
  color: #39811d;
}

.ipsSurroundings h5 {
  font-size: 1rem;
}

.ipsSurroundings > div {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.ipsSurroundings > div > div {
  width: 100%;
}
.ipsSurroundings > div > div > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.ipsSurroundings > div > div > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
}
.ipsSurroundings > div > div > div:nth-of-type(2) > div {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e7e7e7;
  margin-right: 10px;
}
.ipsSurroundings > div > div > div:nth-of-type(2) > div > div:last-of-type {
  color: #232323;
}

/* .peacefulPaymentContainer {
  position: absolute;
  top: 75vh;
}
.peacefulPaymentContainer > div {
  position: sticky;
  top: 12vh;
} */

/* Responsive Design - Media Queries */

/* Responsive Design - Media Queries */
@media (max-width: 1200px) {
  .rulesContainer {
    flex-wrap: wrap;
  }
  .rulesContainer > div {
    flex: 1 1 15rem;
  }
  .ipslocation {
    width: 100%;
  }
  .ipsDescription {
    flex-wrap: wrap;
  }
  .ipsDescription > div {
    flex: 1 1 15rem;
  }
  .individualPeacefulStays > div:not(.imageModall) {
    width: 95% !important;
  }
  .ipsDescription > div:nth-of-type(2) > div {
    width: 100%;
  }
  .ipsSurroundings > div {
    flex-wrap: wrap;
  }
  .ipsSurroundings > div > div {
    flex: 1 1 15rem;
  }
  .ipsGallary {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .ipsGallary > img:nth-of-type(1) {
    flex: 1 0 100%;
  }
  .ipsGallary > img:nth-of-type(2),
  .ipsGallary > img:nth-of-type(3) {
    flex: 1 0 calc(50% - 1rem);
  }
}

@media (max-width: 991px) {
  .ipsGallary {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .ipsGallary > img {
    flex: 1 0 calc(33.3333% - 1rem);
  }
}
.hideOnSmall {
  display: none !important;
}

@media (max-width: 767px) {
  .hideOnSmall {
    display: flex !important;
  }
  .notExpanded {
    line-height: 14px;
    max-height: 24px;
  }

  .showMoreExpanded {
    line-height: normal !important;
    max-height: 1000px !important; /* or some large value that is higher than the potential maximum height of the content */
  }
  .individualPeacefulStays .next,
  .individualPeacefulStays .prev {
    display: none;
  }
  .individualPeacefulStays .modal-content {
    width: 100%;
  }
  .ipsGallary {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .trekBooking {
    margin: 0 !important;
  }
  .ipsDetailsContainerParent {
    flex-wrap: wrap;
  }
  .ipsGallary > img {
    flex: 1 0 calc(50% - 1rem);
  }
  .ipsDetailsHeading > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

/* DatePicker Styles */
/* .react-date-picker {
  font-family: Arial, Helvetica, sans-serif;
  width: 60%;
  margin-left: 10px;
}

.react-date-picker__wrapper,
.react-date-picker__wrapper button {
  background-color: #2c2c2c !important;
  border: 1px solid #444 !important;
}
.react-date-picker__wrapper button {
  border: none !important;
}

.react-date-picker__inputGroup {
  background-color: #2c2c2c !important;
  color: #fff !important;
}

.react-date-picker__inputGroup__input {
  color: #fff !important;
}

.react-date-picker__inputGroup__divider {
  color: #444 !important;
}

.react-date-picker__inputGroup__leadingZero {
  color: #444 !important;
}

.react-date-picker__button__icon {
  stroke: #fff !important;
}

.react-calendar {
  background-color: #2c2c2c !important;
  color: #fff !important;
  border: none !important;
}

.react-calendar__tile {
  background-color: #2c2c2c !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #444 !important;
}

.react-calendar__tile--hasActive,
.react-calendar__tile--active {
  background-color: #ff5e00 !important;
  color: #fff !important;
}

.react-calendar__tile--hasActive:hover,
.react-calendar__tile--active:hover,
.react-calendar__tile--hasActive:focus,
.react-calendar__tile--active:focus {
  background-color: #ff5e00 !important;
  color: #fff !important;
}

.react-calendar__navigation {
  background-color: #2c2c2c !important;
  border: none !important;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  color: #fff !important;
} */
.fixed-bottom-right {
  position: fixed;
  bottom: 5%;
  right: 1%;
  z-index: 100000;
  width: max-content !important;
}
