.terms-of-use-container {
  margin: 10px auto;
  max-width: 900px;
  padding: 20px;
  background: #e3e3e3;
  border-radius: 10px;
}

.terms-of-use-container h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.terms-of-use-container h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 10px;
}

.terms-of-use-container p {
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 20px;
}

.terms-of-use-container strong {
  font-weight: bold;
}
