@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
:root {
  --heading: 30px;
  --subHeading: 20px;
  --primaryText: 18px;
  --secondaryText: 16px;
  --largeHeading: 48px;
  --smallText: 14px;
  --navText: 14px;
  --extraSmallText: 12px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

a {
  color: black;
  text-decoration: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
}

strong {
  font-weight: bold;
}

