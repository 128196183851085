.popularTreks {
  background: #e8e9ea59;
  padding: 1rem 0 0 0;
}

.popularTreksHeading {
  text-align: center;
  font-size: var(--heading);
  padding: 0.6rem 0 0 0;
  z-index: 10;
  color: #ff5e00;
  font-weight: 600;
}
.popularTreksDetails {
  width: 90%;
  margin: auto;
  text-align: center;
  z-index: 10;
  padding-bottom: 4px;
  /* font-size: var(--subHeading); */
  font-size: 16px;
  color: #646464;
}

.popularTrekCards {
  width: 90%;
  margin: auto;
  overflow: hidden;
}

.splide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.splide__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.splide__track {
  overflow: hidden;
  width: 90%;
  padding-right: 100px !important;
}

.splide__arrow {
  width: 2rem;
  height: 2rem !important;
  background-color: #2b78a7 !important;
  border-radius: 50px !important;
}

.splide__arrow svg {
  fill: #fff !important;
}

@media (max-width: 1100px) {
  .splide__track {
    overflow: hidden;
    width: 90%;
    padding-right: 0 !important;
  }
  .popularTrekCards {
    width: 100%;
    margin: auto;
  }
  .splide__track {
    overflow: visible !important;
    width: 90%;
  }
}
@media (max-width: 768px) {
  .popularTreksHeading {
    font-size: 24px;
    padding-bottom: 4px;

    margin-left: auto;
  }
  .popularTrekCards {
    justify-content: center;
    overflow: hidden;
  }
  .popularTreks {
    padding: 1rem 0 0 0;
  }
  .splide__arrow--prev {
    left: 0 !important;
  }
  .splide__arrow--next {
    right: 0 !important;
  }
  .popularTreksDetails {
    font-size: 14px;
  }
  .splide__track {
    overflow: visible !important;
    width: 80%;
  }
}
