.custom {
  width: 60%;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.custom h4 {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.5rem;
  border-radius: 10px;
  width: 50%;
  text-align: center;
  padding: 2rem;
  margin: 0 auto 1rem auto;
  background-color: #2b78a7;
}
.custom > div {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.custom > div label {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: rgba(169, 169, 169, 0.61);
}

.custom > div input {
  margin: 0 0 2rem 0;
  background: transparent;
  border-bottom: 1px solid black;
}

.custom > div select {
  margin: 0 0 2rem 0;
}
.custom > div > div {
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;
  align-items: center;
}

.custom > div > div button {
  padding: 0.4rem 1rem;
  outline: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}

.custom > div > div button:nth-of-type(1) {
  background: #ff5e00;
  color: white;
  border: none;
}
.custom > div > div button:nth-of-type(2) {
  border: 1px solid #2b78a7;
  color: #2b78a7;
  background: transparent;
}

@media (max-width: 768px) {
  .custom {
    width: 90%;
    margin: 1rem auto;
  }
  .custom h4 {
    width: 100%;
  }
}
