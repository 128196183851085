.treks {
  background-color: rgb(240, 240, 240);
  min-height: 100vh;
  /* padding: 2rem 0; */
}

.treksTop {
  background: white;
  padding-bottom: 15px;
}

.treksTopLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px 0;
  align-items: center;
}

.treksTopLeft h2 {
  font-size: var(--heading);
  color: #ff5e00;
  text-align: center;
}

.treksTopLeft h5 {
  font-size: var(--subHeading);
  margin: 1rem 0;
  text-align: center;
}
.treksTopLeft p {
  font-size: 16px;
  color: gray;
  text-align: center;
  width: 80%;
}

.allTreksContainer {
  width: 100%;
}

/* alltreks.js */
.allTreks {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
}

.treksTopRight {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.treksTopRight img:nth-of-type(1) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.treksTopRight img:nth-of-type(2) {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 15rem;
  width: 15rem;
  object-fit: cover;
  border-radius: 20px;
}

.treksBottom {
  display: flex;
}

.treksFilter {
  width: 20%;
  border-radius: 20px;
  background: white;
  margin: 1rem 0;
}

.treksFilter h3 {
  color: #ff5e00;
  margin: 0.5rem 0;
  text-transform: uppercase;
}
.treksFilter > div {
  margin: 2rem 1rem;
}

.treksFilter > div > div {
  display: flex;
  flex-direction: column;
}

.treksFilter input {
  margin: 0 0.4rem;
}

.treksFilter label {
  font-size: var(--smallText);
  text-transform: uppercase;
}

.treksGetInTouch {
  width: 100%;
  height: 30vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://cdn.pixabay.com/photo/2016/12/11/12/02/mountains-1899264_960_720.jpg");
}

.treksGetInTouch h3 {
  text-align: center;
  font-size: var(--heading);
  text-transform: uppercase;
  color: white;
  padding: 1rem 0;
}
.treksGetInTouch > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.treksGetInTouch input {
  flex: 0.3 0.3;
  border: none;
  margin: 0 1rem;
  outline: none;
  border-radius: 20px;
  width: 20rem;
  padding: 0.5rem 1rem;
}

.treksGetInTouch button {
  width: 10rem;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  background: rgb(0, 195, 255);
  color: white;
}

.trekReviews {
  min-height: 70vh;
  padding: 2rem 0;
  background: rgb(0, 195, 255);
}

.trekReviews > h3 {
  width: 90%;
  margin: auto;
  color: white;
  font-size: var(--heading);
  text-transform: uppercase;
}

.reviewContainer {
  width: 90%;
  min-height: 30vh;
}

.reviewContainer {
  min-height: 30vh;
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.reviewContainer > div {
  flex: 1 1 20rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  padding: 2rem 0;
  margin: 1.5rem 0.5rem;
}

.reviewContainer > div:nth-of-type(2) {
  background-color: rgb(255, 131, 74);
  color: white;
  padding: 2rem;
  font-size: var(--secondaryText);
}

.reviewContainer > div > div:nth-of-type(1) {
  width: 90%;
  margin: auto;
}

.reviewImage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
}

.reviewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.reviewContainer > div > div:nth-of-type(3) h3 {
  position: absolute;
  bottom: -35%;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .treksTopLeft p {
    display: none;
  }

  .reviewContainer > div:nth-of-type(2) {
    padding: 2rem 0;
  }
  .treksTopRight > div {
    height: 30vh !important;
  }
  .treksFilter {
    display: none;
  }
  .allTreks {
    justify-content: space-around;
  }
}
